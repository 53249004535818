@layer base {
  .head {
  display: flex;
  flex-flow: row wrap;
  gap: var(--sk-space-24);
}

.headContent {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
}

.advisersPhotos {
  display: none;
}

.welcomeMessage {
  margin-top: var(--sk-space-8);
}

.typeList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-24);
}

.emptyState_icon {
  padding: var(--sk-space-12);
  border-radius: var(--sk-space-12);
  background-color: var(--sk-color-purple-100);
}

.emptyState_button {
  align-self: center;
  margin-top: var(--sk-space-40);
}

@media (--sk-medium-viewport) {
  .headContent {
    min-width: 537px; /* fixed value decided by the designer */
  }

  .advisersPhotos {
    display: flex;
    flex: 0 0 auto;
    margin: 0 auto;
  }
}

@media (--sk-large-viewport) {
  .advisersPhotos {
    flex: 0 0 0;
  }
}

}